/* KEYFRAMES START */

@keyframes bkg_moveR {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* KEYFRAMES END */

/* TOOLTIP START */

#tooltip.formFail {
  background: #eb5b5b;
  text-shadow: none;
}

#tooltip.formFail:before {
  opacity: 1;
}

#tooltip.right_top.formFail:before,
#tooltip.right_middle.formFail:before,
#tooltip.right_bottom.formFail:before {
  border-color: transparent #eb5b5b transparent transparent;
}

#tooltip.left_top.formFail:before,
#tooltip.left_middle.formFail:before,
#tooltip.left_bottom.formFail:before {
  border-color: transparent transparent transparent #eb5b5b;
}

#tooltip.top_left.formFail:before,
#tooltip.top_center.formFail:before,
#tooltip.top_right.formFail:before {
  border-color: #eb5b5b transparent transparent transparent;
}

#tooltip.bottom_left.formFail:before,
#tooltip.bottom_center.formFail:before,
#tooltip.bottom_right.formFail:before {
  border-color: transparent transparent #eb5b5b transparent;
}

/* TOOLTIP END */

/* FORM BLOCK START */

.wsForm_block {
  margin: 20px 0 0;
}

.wsForm_blockName {
  position: relative;
  text-align: center;
  line-height: 100%;
  height: 1.0625em;
  margin: 20px 0 0;
}

.wsForm_blockName:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  background: #8ea6b2;
  height: 1px;
  margin: -1px 0 0;
  z-index: 1;
  content: "";
}

.wsForm_blockName span {
  position: relative !important;
  top: 0px;
  padding: 0 10px;
  background: #f4f6f7;
  z-index: 10;
  line-height: 100%;
  font-size: 1.0625em;
  font-weight: bold;
  color: #6f8b99;
}

/* FORM BLOCK END */

/* FORM FIELD START */

.wsForm_field {
}

.wsForm_fieldName,
.time_separator {
  text-transform: uppercase;
  color: #829199;
  font-weight: bold;
  font-size: 1em;
}

.wsForm_fieldName.imp:after,
.imp .wsForm_fieldName:after {
  color: #ff2121;
  content: " *";
}

.wsForm_fieldName.sel .small_dropdown {
  color: #fff;
  background: #0090db;
}

.wsForm_fieldError {
  display: none;
  margin-top: 10px;
  color: #ff2121;
  line-height: 115%;
}

.wsForm_fieldError:before {
  float: left;
  height: 14px;
  width: 15px;
  margin: 2px 3px 0 0;
  background: transparent url("../img/workspace_form.png") no-repeat 0 -45px;
  content: "";
}

.fail .wsForm_fieldError {
  display: block;
}

.wsForm_field .wsForm_fieldHint {
  margin-top: 5px;
  font-size: 1em;
  color: #88969e;
  line-height: 130%;
}

.wsForm_field .wsForm_radioGroup .wsForm_fieldHint,
.wsForm_field .wsForm_checbox .wsForm_fieldHint {
  padding-left: 23px;
}

.wsForm_fieldDescription {
  margin-bottom: 15px;
}

/* FORM FIELD END */

/* TEXT FIELDS START */

.wsForm_text {
  position: relative;
  margin-right: 20px;
}

.chat_profile-crm_data .single_date_picker {
  margin-right: 20px;
}
.wsForm_text.hidden {
  display: none;
}

#msg-module .single_date_picker {
  margin-top: 5px;
  height: 48px;
}

.wsForm_text input,
.wsForm_text textarea {
  display: block;
  border-radius: 2px;
  background: #cee6f2;
  border: none;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  outline: none;
  padding: 10px 8px;
  width: 100%;
  color: #2b2b2b;
  border: 2px #cee6f2 solid;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
  resize: vertical;
}

.wsForm_text input:-webkit-autofill {
  -webkit-text-fill-color: #2b2b2b !important;
  -webkit-box-shadow: inset 0 0 0 48px #cee6f2 !important;
  color: #cee6f2 !important;
}

.wsForm_text input:focus:not(.input_date),
.wsForm_text textarea:focus,
.additional_date_field:focus {
  border-color: #0090db;
  background: #fff;
}

.wsForm_text input::-webkit-input-placeholder,
.wsForm_text textarea::-webkit-input-placeholder {
  color: #73aecc;
}

.wsForm_text input::-ms-clear {
  display: none;
}

.wsForm_text textarea {
  overflow: auto;
}

.wsForm_field.fail .wsForm_text input,
.wsForm_field.fail .wsForm_text textarea {
  background: #f9ebea;
  border-color: #f9ebea;
}

.wsForm_field.fail .wsForm_text input:focus,
.wsForm_field.fail .wsForm_text textarea:focus {
  border-color: #f6b5ac;
}

.wsForm_field.fail .wsForm_text input::-webkit-input-placeholder,
.wsForm_field.fail .wsForm_text textarea::-webkit-input-placeholder {
  color: #d5afaa;
}

/* COLOR START */

.wsForm_color {
  position: relative;
  overflow: hidden;
}

.wsForm_colorHex {
  position: absolute;
  left: 47px;
  right: 0;
  padding-right: 16px;
}

.wsForm_color input {
  float: left;
  background: #cee6f2;
  outline: none;
}

.wsForm_color input[type="text"] {
  display: block;
  border-radius: 2px;
  border: 2px #cee6f2 solid;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  padding: 8px 6px;
  width: 100%;
  color: #2b2b2b;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
  resize: vertical;
}

.wsForm_colorHex input:focus {
  border-color: #0090db;
  background: #fff;
}

.wsForm_color input[type="color"] {
  width: 0;
  height: 0;
  border: none;
  padding: 22px;
  border-radius: 2px;
  cursor: pointer;
  background: transparent;
  position: relative;
  z-index: 90;
}
/* -webkit */
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
/* firefox */
input[type="color"]::-moz-focus-inner {
  border: none;
  padding: 0;
}
input[type="color"]::-moz-color-swatch {
  border: none;
}
.wsForm_color:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 44px;
  background: #cee6f2;
  top: 0;
  left: 0;
  border-radius: 2px;
  z-index: 91;
}
.wsForm_color span {
  position: absolute;
  width: 34px;
  height: 34px;
  display: block;
  top: 5px;
  left: 5px;
  border-radius: 2px;
  box-shadow: 0 0 3px 1px rgba(115, 174, 204, 0.5); /* #73aecc => rgba */
  z-index: 99;
  cursor: pointer;
}

/* COLOR END */

/* DATE START */

.wsForm_text.date {
  margin-right: 0px;
  width: 125px;
  overflow: hidden;
  cursor: pointer;
}

.wsForm_text.date input {
  padding-left: 33px;
  cursor: pointer;
}

.wsForm_text.date:before,
.wsForm_text.date:after {
  position: absolute;
  content: "";
}

.wsForm_text.date:before {
  top: 15px;
  left: 10px;
  width: 18px;
  height: 18px;
  background: transparent url("../img/workspace_icons.png") 0 -18px no-repeat;
}

.wsForm_text.date:after {
  top: 0;
  right: -45px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.calendar_wrap {
  position: fixed;
  width: 206px;
  height: 221px;
  z-index: 4;
  white-space: normal;
}

.calendar_wrap:before,
.calendar_wrap:after {
  position: absolute;
  content: "";
}

.calendar_wrap:before {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  border-radius: 5px;
}

.calendar_wrap:after {
  width: 14px;
  height: 14px;
  background: #fff;
  transform: rotate(45deg);
  top: -7px;
  left: 12px;
  border-radius: 2px 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.32);
  z-index: 1;
}

.calendar_wrap.top::before {
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.32) !important;
}
.calendar_wrap.top::after {
  top: calc(100% - 7px) !important;
}

.calendar_wrap .calendar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 5px;
  z-index: 2;
}
/* DATE END */

/* TEXT FIELDS END */

/* INPUT FILE FIELDS START */

.wsForm_file,
.wsForm_file_t2 {
  position: relative;
  border-radius: 2px;
  background: #cee6f2;
  border: 2px #cee6f2 solid;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  outline: none;
  padding: 10px 8px 10px 33px;
  color: #a3becc;
}

.wsForm_file {
  border-color: #0090db;
  background: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
}
.wsForm_file.focus,
.wsForm_file_t2.invert {
  border-color: #0090db;
  background: #fff;
}

.wsForm_file:before,
.wsForm_file_t2 label:before {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 15px;
  height: 15px;
  background: transparent url("../img/workspace_form.png") no-repeat 0 -15px;
  content: "";
}

.wsForm_file.load:before,
.wsForm_file_t2.load label:before {
  cursor: default;
  background-position: 0 -30px;
  -webkit-animation: load 0.8s linear infinite;
  -moz-animation: load 0.8s linear infinite;
  -ms-animation: load 0.8s linear infinite;
  -o-animation: load 0.8s linear infinite;
}

.wsForm_file span,
.wsForm_file_t2 label {
  cursor: default;
  color: #73aecc;
}

.wsForm_file.filled span,
.wsForm_file_t2.filled label {
  color: #2b2b2b;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  direction: rtl;
  text-align: left;
}

.wsForm_file.filled span,
.wsForm_file_t2.filled label span {
  overflow: hidden;
  width: 100%;
  display: block;
}

.wsForm_file.load span,
.wsForm_file_t2.load label {
  color: #0090db;
}

.wsForm_file input,
.wsForm_file_t2 input {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}

.wsForm_file.load input,
.wsForm_file_t2.load input {
  display: none;
}

.wsForm_field.fail .wsForm_file {
  background: #f9ebea;
  border-color: #f9ebea;
}

.wsForm_field.fail .wsForm_file.focus,
.wsForm_field.fail .wsForm_file_t2.focus {
  border-color: #f6b5ac;
}

.wsForm_field.fail .wsForm_file span,
.wsForm_field.fail .wsForm_file_t2 label {
  color: #d5afaa;
}

.wsForm_field.fail .wsForm_file + .wsForm_fieldHint,
.wsForm_field.fail .wsForm_file_t2 + .wsForm_fieldHint {
  display: none;
}

.wsForm_file_t2 {
  cursor: pointer;
  padding: 0px;
}

.wsForm_file_t2 [type="file"] {
  display: none;
}

.wsForm_file_t2 label {
  display: block;
  padding: 10px 8px 10px 33px;
  cursor: pointer;
}

/* INPUT FILE FIELDS END */

/* SELECT FIELDS START */

.wsForm_select {
  position: relative;
  background: #cee6f2;
  border-radius: 2px;
  cursor: pointer;
  border: 2px #cee6f2 solid;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
}

.wsForm_select.focus {
  border-color: #0090db;
  background: #fff;
}
.wsForm_select.selected {
  /*border-color: #0090DB;
	background: #cee6f2;*/
}

.wsForm_select:before,
.wsForm_select:after {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  cursor: inherit;
  border-left: 2px #0090db solid;
  height: 8px;
}

.wsForm_select:before {
  transform: rotate(45deg);
  margin: -4px 0 0 18px;
}

.wsForm_select:after {
  transform: rotate(-45deg);
  margin: -4px 0 0 13px;
}

.wsForm_select select {
  border: none;
  color: #2b2b2b;
  background-color: transparent;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  width: 100%;
  padding: 10px 8px 10px 33px;
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: inherit;
  text-overflow: "";
  text-indent: 0.01px;
  height: 44px;
  line-height: 100%;
}

.wsForm_select select::-ms-expand {
  display: none;
}

.wsForm_select select:focus {
  outline: none;
}

.wsForm_field.fail .wsForm_select,
.statsAgentSettings_item.fail .wsForm_text input {
  background: #f9ebea;
  border-color: #f9ebea;
}

.wsForm_field.fail .wsForm_select:before,
.wsForm_field.fail .wsForm_select:after,
.wsForm_field.fail .wsForm_select.focus {
  border-color: #f6b5ac;
}

.wsForm_field.fail .wsForm_select select {
  color: #d5afaa;
}

.wsForm_select select {
  color: #2b2b2b !important;
}

/* MOZILLA FIX START */
@-moz-document url-prefix() {
  .wsForm_select {
    overflow: hidden;
  }
  .wsForm_select select {
    width: 110%;
    -moz-outline: none;
    text-indent: 0.01px;
    text-overflow: "";
  }

  .wsForm_select select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}
/* MOZILLA FIX END */

/* SELECT FIELDS END */

/* SINGLE AND MULTI CHOICE START */

.wsForm_list {
  margin: 5px 0 0;
}

.wsForm_checkbox,
.wsForm_radio,
.wsForm_toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.wsForm_checkbox,
.wsForm_radio {
  cursor: pointer;
  overflow: visible;
  display: block;
  line-height: 18px;
  vertical-align: middle;
  color: #0090db;
}

.wsForm_checkbox.hidden {
  display: none;
}

.wsForm_toggle input[type="checkbox"],
.wsForm_radio input[type="radio"],
.wsForm_checkbox input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.wsForm_checkbox span,
.wsForm_radio span {
  float: left;
  position: relative;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  border: 2px #bec2c4 solid;
  border-radius: 3px;
  background: #fff;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
}

.wsForm_checkbox:hover span,
.wsForm_radio:hover span {
  border-color: #0090db;
  /* 		background: #0090DB; */
}

.wsForm_checkbox input[type="checkbox"] + span:before,
.wsForm_checkbox input[type="checkbox"] + span:after,
.wsForm_radio input[type="radio"] + span:before {
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
}

.wsForm_checkbox span:before {
  position: absolute;
  top: 6px;
  left: 3px;
  border-top: 3px transparent solid;
  transform: rotate(-45deg);
  width: 10px;
  content: "";
}

.wsForm_checkbox span:after {
  position: absolute;
  top: 7px;
  left: 1px;
  border-top: 3px transparent solid;
  transform: rotate(45deg);
  width: 6px;
  content: "";
}

.wsForm_radio span:before {
  position: absolute;
  top: 6px;
  left: 3px;
  background: transparent;
  height: 10px;
  width: 10px;
  content: "";
}

.wsForm_radio span:before {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
}

.wsForm_radio span,
.wsForm_radio span:before {
  border-radius: 100%;
}

/* UNCHECKED START */
.wsForm_checkbox input[type="checkbox"]:focus + span,
.wsForm_radio input[type="radio"]:focus + span {
  border-color: #0090db;
  background: #fff;
}

.wsForm_checkbox:hover input[type="checkbox"] + span:before,
.wsForm_checkbox:hover input[type="checkbox"] + span:after,
.wsForm_radio:hover input[type="radio"] + span:before {
  border-color: #fff;
  background: #fff;
}
/* UNCHECKED STOP */

/* CHECKED START */
.wsForm_checkbox input[type="checkbox"]:checked + span,
.wsForm_radio input[type="radio"]:checked + span {
  border-color: #0090db;
  background: #0090db;
}

.wsForm_checkbox input[type="checkbox"]:checked:focus + span,
.wsForm_radio input[type="radio"]:checked:focus + span,
.wsForm_checkbox:hover input[type="checkbox"]:checked + span,
.wsForm_radio:hover input[type="radio"]:checked + span {
  border-color: #0090db;
  background: #fff;
}

.wsForm_checkbox input[type="checkbox"]:checked + span:before,
.wsForm_checkbox input[type="checkbox"]:checked + span:after,
.wsForm_radio input[type="radio"]:checked + span:before {
  border-color: #fff;
  background: #fff;
}

.wsForm_checkbox input[type="checkbox"]:checked:focus + span:before,
.wsForm_checkbox input[type="checkbox"]:checked:focus + span:after,
.wsForm_radio input[type="radio"]:checked:focus + span:before,
.wsForm_checkbox:hover input[type="checkbox"]:checked + span:before,
.wsForm_checkbox:hover input[type="checkbox"]:checked + span:after,
.wsForm_radio:hover input[type="radio"]:checked + span:before {
  border-color: #0090db;
  background: #0090db;
}
/* CHECKED STOP */

/* SHOW THE FOLLOWING FIELD START */

.wsForm_field.related.checkbox {
  margin-bottom: 5px;
}

.wsForm_field.related.sel ~ .wsForm_field.related {
  display: none;
}

.wsForm_checkbox.related ~ .wsForm_field.related {
  display: block;
}

/* SHOW THE FOLLOWING FIELD END */

/* FANCYCHOICE START */

.wsForm_fancyChoice .wsForm_fancyChoice_container {
  background: #cee6f2;
  border: 2px #cee6f2 solid;
  border-radius: 2px;
  position: relative;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
  color: #2b2b2b;
  cursor: pointer;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  font-size: 0px;
  outline: none;
  padding: 7px 5px;
  min-height: 24px;
  line-height: 100%;
}

.wsForm_field.fail .wsForm_fancyChoice .wsForm_fancyChoice_container {
  background: #f9ebea;
  border-color: #f9ebea;
}

.wsForm_fancyChoice .wsForm_fancyChoice_container.focused {
  border-color: #0090db;
  background: #fff;
  cursor: text;
}
.wsForm_field.fail .wsForm_fancyChoice .wsForm_fancyChoice_container.focused {
  border-color: #f6b5ac;
}

.wsForm_fancyChoice .wsForm_fancyChoice_container .wsForm_fancyChoice_textarea {
  background: transparent;
  resize: none;
  color: #73aecc;
  margin: 2px;
  padding: 5px 0px;
  cursor: pointer;
  outline: 0;
  border: 0;
  display: inline-block;
  white-space: nowrap;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  font-size: 16px;
  line-height: 100%;
}

.wsForm_field.fail
  .wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_textarea {
  color: #d5afaa;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_textarea[contentEditable="true"]:first-of-type:empty:not(:focus)::before {
  content: attr(data-text);
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_textarea:focus,
.wsForm_field.fail
  .wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_textarea:focus {
  cursor: text;
  color: #2b2b2b;
}

.wsForm_fancyChoice .wsForm_fancyChoice_container .wsForm_fancyChoice_tag {
  background: #0090db;
  color: #fff;
  border-radius: 2px;
  padding: 5px 8px;
  padding-right: 29px;
  border-bottom: none;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 2px;
  font-size: 16px;
  line-height: 100%;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_tag::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -8px;
  background: transparent url(../img/msg_region_remove.png) 0 -16px no-repeat;
  width: 16px;
  height: 16px;
  right: 7px;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_tag:hover::after {
  background-position: 0px 0px;
}

.wsForm_field.fail
  .wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_tag {
  background: #f6b5ac;
}
.wsForm_fancyChoice .wsForm_fancyChoice_container .wsForm_fancyChoice_list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
  width: 100%;
  display: none;
  padding-right: 3px;
  box-sizing: border-box;
}

.wsForm_fancyChoice .wsForm_fancyChoice_container .wsForm_fancyChoice_scroll {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f0f0f0;
  margin-right: 5px;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_scroll::-webkit-scrollbar-button {
  display: none;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_scroll::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #f0f0f0;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #ccc;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #42abe1;
}

.wsForm_field.fail
  .wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #f6b5ac;
}

.wsForm_fancyChoice .wsForm_fancyChoice_hidden_list {
  display: none;
}
.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_list
  .wsForm_fancyChoice_item {
  padding: 5px;
  border-radius: 2px;
  background: #fff;
  line-height: 130%;
  font-size: 15px;
  font-weight: bold;
  color: #525f67;
  border-bottom: 1px solid #f0f0f0;
}

.wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_list
  .wsForm_fancyChoice_item:hover {
  background-color: #e4f1f7;
  cursor: pointer;
  margin-top: -1px;
  padding-top: 6px;
}

.wsForm_field.fail
  .wsForm_fancyChoice
  .wsForm_fancyChoice_container
  .wsForm_fancyChoice_list
  .wsForm_fancyChoice_item:hover {
  background-color: #f9ebea;
}

/* FANCYCHOICE END */

/* wsForm_toggle */
.wsForm_toggle .wsForm_toggle_controlView {
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 20px;
  width: 36px;
  border-radius: 10px;
  background: #f2a9a9;
  -webkit-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  -ms-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  transition: background 0.1s linear;
}
.wsForm_toggle input[type="checkbox"]:checked + .wsForm_toggle_controlView {
  background: #9fe49f;
}
.wsForm_toggle .wsForm_toggle_controlView:before {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: left 0.1s linear;
  -moz-transition: left 0.1s linear;
  -ms-transition: left 0.1s linear;
  -o-transition: left 0.1s linear;
  transition: left 0.1s linear;
  content: "";
}
.wsForm_toggle
  input[type="checkbox"]:checked
  + .wsForm_toggle_controlView:before {
  left: 18px;
}
#wsForm_toggable
  .wsForm_toggle
  input[type="checkbox"]:focus
  + .wsForm_toggle_controlView {
  border: 0;
  margin: 0;
  border-radius: 12px;
}
#wsForm_toggable .wsForm_toggle .wsForm_toggle_controlView:after {
  border: 0;
}
.autoreplies_preview #wsForm_toggable {
  margin-top: 25px;
  line-height: 16px;
}
.wsForm_checkbox_with_radio #wsForm_toggable {
  margin-top: 10px;
}
.wsForm_toggle_labelText {
  display: inline-block;
  position: relative;
  top: -3px;
  left: 3px;
  color: #829199;
  text-transform: none;
  font-weight: bold;
}
.wsForm_toggle .wsForm_toggle_labelText_on {
  color: #525f67;
  display: none;
}
.wsForm_toggle .wsForm_toggle_labelText_off {
  display: block;
}
.wsForm_toggle
  input[type="checkbox"]:checked
  + span
  + .wsForm_toggle_labelText
  .wsForm_toggle_labelText_on {
  display: block;
}
.wsForm_toggle
  input[type="checkbox"]:checked
  + span
  + .wsForm_toggle_labelText
  .wsForm_toggle_labelText_off {
  display: none;
}

/* SINGLE AND MULTI CHOICE END */

/** 
  * FORM INPUT TYPE RANGE COMPONENT STYLES START 
	*/

.wsForm_range {
  height: 48px;
  overflow: hidden;
}

.wsForm_range_score {
  color: #0090db;
  font-weight: normal;
  text-transform: none;
  padding-left: 10px;
}

.wsForm_range input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  width: 100%;
  margin: 22px 0px;
  position: relative;
  cursor: pointer;
}

.wsForm_range input[type=range]:focus {
  outline: none;
}

/* WEBKIT SOLUTION START */

.wsForm_range input::-webkit-slider-runnable-track {
  width: 100%;
  box-shadow: none;
  border: none;
  height: 4px;
  border-radius: 2px;
  background: #cee3ed;
}

.wsForm_range input::-webkit-slider-thumb {
  box-shadow: none;
  width: 16px;
  height: 16px;
  background: #cee3ed;
  border-radius: 50%;
  -webkit-appearance: none;
  border: 2px #fff solid;
  z-index: 10;
  cursor: pointer;
  margin-top: -6px;
}

.wsForm_range input::-webkit-slider-runnable-track,
.wsForm_range input::-webkit-slider-thumb {
  -webkit-transition: background 0.1s linear;
}

.wsForm_range input:hover::-webkit-slider-runnable-track,
.wsForm_range input:hover::-webkit-slider-thumb {
  background: #0090db;
}

/* WEBKIT SOLUTION END */

/* MOZILLA SOLUTION START */

.wsForm_range input::-moz-range-track {
  width: 100%;
  box-shadow: none;
  border: none;
  height: 4px;
  border-radius: 2px;
  background: #cee3ed;
}

.wsForm_range input::-moz-range-thumb {
  position: absolute;
  top: 50%;
  box-shadow: none;
  width: 16px;
  height: 16px;
  background: #cee3ed;
  border-radius: 50%;
  -moz-appearance: none;
  border: 2px #fff solid;
  z-index: 10;
}

.wsForm_range input::-moz-range-track,
.wsForm_range input::-moz-range-thumb {
  -moz-transition: background 0.1s;
}

.wsForm_range input:hover::-moz-range-track,
.wsForm_range input:hover::-moz-range-thumb {
  background: #0090db;
}

.wsForm_range input::-moz-focus-outer {
  border: none;
}

/* MOZILLA SOLUTION END */

/* BUTTON START */

.wsForm_button {
  font: normal 1em "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  font-size: 0.875em;
  line-height: 100%;
  font-weight: bold;
  background: transparent;
  border: 2px #00a1f5 solid;
  color: #0090db;
  padding: 10px 15px 10px;
  margin: 20px 20px 0;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  position: relative;
}

.wsForm_button:hover,
.wsForm_button:active,
.wsForm_button:focus {
  border-color: #0090db;
  background-color: #0090db;
  color: #fff;
}

.wsForm_button::-moz-focus-inner {
  border: 0;
}

.wsForm_button:focus {
  border-color: #005e8e;
}

.wsForm_button.apply,
.wsForm_button.cancel {
  padding-left: 40px;
}

.wsForm_button.fl_r {
  margin-right: 0px;
}

.wsForm_button.apply:before,
.wsForm_button.apply:after {
  position: absolute;
  border-left: 4px #0090db solid;
  content: "";
}

.wsForm_button.apply:before {
  top: 14px;
  left: 15px;
  height: 8px;
  transform: rotate(-45deg);
}

.wsForm_button.apply:after {
  top: 10px;
  left: 21px;
  height: 14px;
  transform: rotate(45deg);
}

.wsForm_button.cancel:before,
.wsForm_button.cancel:after {
  position: absolute;
  top: 8px;
  left: 20px;
  height: 18px;
  border-left: 4px #0090db solid;
  content: "";
}

.wsForm_button.cancel:before {
  transform: rotate(-45deg);
}

.wsForm_button.cancel:after {
  transform: rotate(45deg);
}

.wsForm_button:focus:before,
.wsForm_button:focus:after,
.wsForm_button:hover:before,
.wsForm_button:hover:after {
  border-color: #fff !important;
}

.wsForm_button.green {
  border-color: #3ebb3b;
  background-color: #3ebb3b;
  color: #fff;
}

.wsForm_button.green:hover,
.wsForm_button.green:active,
.wsForm_button.green:focus {
  border-color: #3ebb3b;
  background-color: #3ebb3b;
  color: #fff;
}

.wsForm_button.green:focus {
  border-color: #2b8129;
}

.wsForm_button.green:before,
.wsForm_button.green:after {
  border-color: #fff;
}

.wsForm_button.red {
  border-color: #dd3e41;
  background-color: #dd3e41;
  color: #fff;
}

.wsForm_button.red:hover,
.wsForm_button.red:active,
.wsForm_button.red:focus {
  border-color: #dd3e41;
  background-color: #dd3e41;
  color: #fff;
}

.wsForm_button.red:focus {
  border-color: #9a1b1e;
}

.wsForm_button.red:before,
.wsForm_button.red:after {
  border-color: #fff;
}

.wsForm_button[disabled],
.wsForm_button[disabled]:hover,
.wsForm_button[disabled]:active,
.wsForm_button[disabled]:focus {
  color: #eaeff0;
  border-color: #eaeff0;
  background-color: #fff;
  cursor: not-allowed;
}

/* BUTTON END */

/* inputMultiplyCheck START */

.wsForm_multiChoice {
  position: relative;
}
.wsForm_multiChoiceInput {
  min-height: 24px;
  border-radius: 2px;
  background: #cee6f2;
  border: none;
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
  outline: none;
  padding: 10px 8px;
  color: #2b2b2b;
  border: 2px #cee6f2 solid;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
  position: relative;
  color: #2b2b2b;
  cursor: pointer;
}
.wsForm_multiChoiceInput.focus {
  background: #fff;
  border: 2px #0090db solid;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  -webkit-transition: border-color 0.1s, background 0.1s;
  -moz-transition: border-color 0.1s, background 0.1s;
  -ms-transition: border-color 0.1s, background 0.1s;
  -o-transition: border-color 0.1s, background 0.1s;
  transition: border-color 0.1s, background 0.1s;
  color: #2b2b2b;
}
.wsForm_multiChoiceInput.full {
  padding: 6px;
  overflow: hidden;
}

.wsForm_multiChoiceInput.full:before,
.wsForm_multiChoiceInput.full:after {
  content: " ";
  display: table;
}
.wsForm_multiChoiceInput.full:after {
  clear: both;
}
.wsForm_multiChoiceInput_placeholder {
  position: absolute;
  top: 12px;
  left: 10px;
  cursor: text;
  color: #73aecc;
  font-size: 1em;
}
.wsForm_tagWrap {
  float: left;
  color: #0090db;
  background: transparent;
  line-height: 125%;
  font-size: 1em;
  position: relative;
  margin: 2px;
}
.stretch_element {
  position: absolute;
  overflow: hidden;
}
.stretch_element input {
  font: normal 100%/1.5 "PT Sans", Arial, sans-serif, Candara, Segoe, "Segoe UI",
    Optima;
}
.wsForm_tag {
  background: #0090db;
  color: #fff;
  border-radius: 2px;
  padding: 5px 8px;
  padding-right: 29px;
  border-bottom: none;
  cursor: pointer;
}
.wsForm_tag:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -8px;
  background: transparent url("../img/msg_region_remove.png") 0 -16px no-repeat;
  width: 16px;
  height: 16px;
  right: 7px;
}
.wsForm_tag:hover:after {
  background-position: 0 0px;
}

.multiChoiceInput_textarea {
  background: transparent;
  resize: none;
  vertical-align: middle;
  padding: 0;
  display: table-cell;
  width: 100%;
  outline: 0;
  border: 0;
}
.multiChoiceInput_textarea:focus {
  border: 0;
  outline: 0;
}
.multiChoiceInput_textarea_wrap {
  height: 21px;
  vertical-align: top;
  padding: 2px 2px 2px 5px;
  float: left;
  margin-top: -5px;
}
.focus .multiChoiceInput_textarea_wrap {
  margin-right: 50px;
}
.wsForm_multiChoiceInput.full .multiChoiceInput_textarea_wrap {
  margin-top: 0;
}
.empty_search {
  font-size: 1em;
  color: #829199;
  margin: 5px;
}
.wsForm_multiChoice_list {
  position: absolute;
  bottom: auto;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 5px;
  overflow: hidden;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
  width: 100%;
  height: 180px;
  display: none;
}
.wsForm_multiChoice_list.show {
  display: block;
}
.wsForm_multiChoice_list.height_stretch_block {
  height: auto;
}
.wsForm_multiChoice_list .wsScroll .wsScroll_content {
  padding: 5px 13px 5px 5px;
}
.wsForm_multiChoice_list.height_stretch_block .wsScroll .wsScroll_content {
  position: relative;
}
#skillEditor_inputMultiplyCheck .inputMultiplyCheck_content {
  position: relative;
  max-height: 205px;
}
.wsForm_multiChoice_border {
  position: absolute;
  background: #f0f0f0;
  top: 5px;
  bottom: 5px;
  right: 5px;
  width: 3px;
  border-radius: 1px;
  z-index: 10;
}
.wsForm_multiChoice_itemWrap {
  padding: 5px 5px 0;
  border-radius: 2px;
  background: #fff;
  line-height: 130%;
  font-size: 1em;
  font-weight: bold;
  color: #525f67;
}
.wsForm_multiChoice_itemWrap:hover,
.wsForm_multiChoice_itemWrap.hover {
  background-color: #e4f1f7;
  cursor: pointer;
  margin-top: -1px;
  padding-top: 6px;
}
.wsForm_multiChoice_itemWrap.hidden {
  display: none;
}
.wsForm_multiChoice_item {
  padding-bottom: 5px;
  border-bottom: 1px solid #f0f0f0;
}
.wsForm_multiChoice_itemWrap.last .wsForm_multiChoice_item,
.wsForm_multiChoice_itemWrap:last-child .wsForm_multiChoice_item {
  border-bottom: none;
}
.wsForm_multiChoice_empty {
}
/* inputMultiplyCheck END */

.copy_blue_btn {
  font-weight: normal;
  padding: 3px 5px;
  position: relative;
  background: #e4f1f7;
  text-transform: none;
  color: #0090db;
  cursor: pointer;
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 10px;
}

.rightEditor_list label {
  margin: 10px 0;
}

.wsForm_section_border {
  border-bottom: 1px solid #e9eeef;
}

.wsForm_processing::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  cursor: wait;
}

.chat_profile-crm_line .wsForm_fieldName {
    margin: 15px 0 5px 0;
}